@import "../../../assets/scss/variables.scss";

.btn_back {
  border-radius: 36px;
  text-align: center;
  height: 40px;
  max-width: 300px;
  min-width: 150px;
  padding: 0 20px;
}

.button_text {
  margin: 0;
  font-size: $font-14;
  font-weight: $medium;
  text-align: center;
}

.btn_back:disabled {
  max-width: 300px;
  min-width: 150px;
  height: 40px;
  opacity: 0.7;
  cursor: no-drop;
  background-color: $secondaryColor;
}
