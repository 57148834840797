@import "../../../assets/scss/variables.scss";

.navbar_back {
  height: 60px;
  background-color: $white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 1;
}

.avatar_back {
  width: 35px;
  height: 35px;
}

.avatar_image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
}

.support_link_back {
  background: transparent;
  border-radius: 8px;
  border: 1px solid transparent;
  cursor: pointer;
}

.support_link_back:hover {
  background: rgba($accentColor, $alpha: 0.15);
  border: 1px solid $accentColor;

  .support_link_text {
    color: $accentColor;
  }
}

.support_link_text {
  font-size: 12px;
  color: $primaryColor;
}
