@import "../../assets/scss/variables.scss";

.auth_card_background {
  height: 80%;
  width: 500px;
  background-color: $white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.021);
}

.auth_form {
  width: 80%;
  margin: 0 auto;
}

.illustration_header {
  font-size: $font-20;
  font-family: $firaSans;
  text-align: center;
  color: $primaryColor;
  margin: 10px;
}

.illustration_body {
  font-size: $font-14;
  width: 80%;
  text-align: center;
  margin: 0 auto;
  color: $secondaryColor;
}

.navigation_link_label {
  font-size: $font-12;
  text-align: center;
  color: $secondaryColor;
  margin: 0;
}

.navigation_link {
  font-size: $font-16;
  font-weight: $medium;
  text-align: center;
  color: $accentColor;
  margin: 0;
  text-decoration: none;
}

.navigation_link:hover {
  color: $accentColor;
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .auth_card_background {
    width: 100%;
    height: 100%;
  }
}