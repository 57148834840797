@tailwind base;
@tailwind components;
@tailwind utilities;

.d-none {
  display: none;
}


img {
  max-width: 100%;
}

.container-fluid.p-2.h-100.login_bnr {
  /* background: linear-gradient(341deg, #eff6ff, #fdf3d9) !important; */
  /* background: url(assets/images/login-bg.jpg) center center / cover fixed; */
  background: linear-gradient(308deg, rgba(136, 191, 236, 0.1), rgba(252, 198, 61, 0.95)), url(assets/images/login-banner-bg.jpg) 50% no-repeat;
  background-position: center;
  background-size: cover;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: normal;
}

code {
  font-family: 'Inter';
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ddd;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #272727;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333;
}

.text-error {
  color: red !important;
}

.text-amber {
  color: #FFBF00 !important;
}

body {
  background: #fff !important;
}

.MuiSnackbar-root {
  top: 90px !important;
  right: 25px !important;
}

.displayEllipsis {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.homebanner img {
  max-width: 100%;
}

img.ondc_n_logo {
  width: 70px;
  margin-bottom: 20px;
}

/* .homebanner {
  background: url(assets/images/category/hesa-banner2.jpg);
  background-size: cover;
  padding: 95px 0;
  background-repeat: no-repeat;
} */

.homebanner {
  background: linear-gradient(90deg, #fff7f7 40%, #ffe394);
  padding: 15px 0 0px;
  background-repeat: no-repeat;
}

.bannerDescription h5 {
  font-size: 42px;
  color: #0a0a55;
  font-family: 'Inter';
  font-weight: 700;
  line-height: 58px;
}

.bannerDescription h5 span {
  color: #FFBF00;
  font-weight: 800;
}

.bannerDescription p {
  font-size: 20px;
  font-family: 'Inter';
  margin-bottom: 30px;
}

.bannerDescription button.buy_now_btn {
  background: #0a0a55 !important;
  color: #fff !important;
  border-radius: 50px !important;
  padding: 10px 30px !important;
}

.bannerDescription button.buy_now_btn:hover {
  background: #ffbf00 !important;
}

.agbn {
  width: 100%;
}


.agr_banner_btm {
  position: relative;
  background: url(assets/images/agri_banner.png) center center / cover fixed;
  width: 100%;
  padding: 50px;
  border-radius: 10px;
}

.agr_banner_btm h3 {
  color: #fff;
  font-size: 40px;
  font-family: 'Inter';
  font-weight: 700;
}

.agr_banner_btm p {
  color: #fff;
  font-size: 20px;
  font-family: 'Inter';
}


.agr_banner_btm::after {
  content: "";
  background: #000;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .25;
}

.banr_info {
  position: relative;
  z-index: 99;
  text-align: center;
}

.shop_buy_btn {
  background: #fdbc01 !important;
  color: #fff !important;
  border-radius: 50px !important;
  padding: 10px 30px !important;
}

.shop_buy_btn:hover {
  background: #0a0a55 !important;
}

.info img {
  width: 100px;
}

.info.text-center {
  border-radius: 8px;
  background: #fff;
  margin: 10px 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 8px !important;
  padding: 10px;
}

.about_descr {
  font-family: 'Inter';
  margin: 60px 0px;
}

.about_descr h3 {
  font-size: 32px;
  font-weight: 700;
}

.info h5 {
  font-size: 16px;
  margin: 15px 0px;
}

.int_reach {
  margin: 45px 0px;
}

.hesa_partner {
  font-family: 'Inter';
  margin: 60px 0 60px;
}

.part_info h3 {
  font-size: 32px;
  font-weight: 700;
}

.part_info p {
  line-height: 30px;
  margin-top: 12px;
}

.prtnr_bg {
  background: linear-gradient(308deg, rgba(237, 247, 255, .9490196078431372), rgba(250, 243, 225, .9098039215686274)), url(assets/images/hesa_login_bg.jpg) 50% no-repeat !important;
  background-position: center top;
  width: 100%;
  padding: 50px 50px;
  border-radius: 10px;
  opacity: 0.8;
}

.about_descr img.abt {
  width: 125px;
  margin-bottom: 15px;
}

.reg_office {
  padding-right: 16px;
}

.disclaimer h6 {
  font-size: 18px;
  margin-bottom: 15px;
}

.disclaimer {
  color: #fff;
  font-family: 'Inter';
}

.disclaimer p {
  font-size: 13px;
}

.Userlogin {
  color: #fff;
  text-decoration: none;
}

.seller_name {
  font-size: 12px;
  font-weight: 600;
  color: #007E00;
}

.product_title {
  font-size: 14px;
  margin: 0 0 8px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product_price {
  font-weight: 600;
}

.address_frm.container label {
  font-size: 17px !important;
  font-weight: 500;
  color: #454545;
  top: -5px;
}

.address_frm.container input {
  background: #0a0a5908;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
}

.address_frm.container input:focus {
  border: none !important;
}

.slc_address input.add_value{
  color: black;
  padding: 9.5px 10px;
  width: 100%;
  font-family: 'Inter';
  width: 100%;
  border: solid 1px #B9B9B9;
}

.slc_address input.add_value:focus {
  border: none !important;
}

input:focus-visible {
  outline:solid 1px #B9B9B9;
}

.slc_address{
  position: relative;
}

.slc_address ul.list-group li {
  border: none;
  font-size: 13px;
  font-family: 'Inter';
  background: transparent;
  border-bottom: solid 1px #f1f1f1;
  border-radius: 0;
}
.slc_address ul.list-group {
  height: 215px;
  overflow-y: auto;
  position: absolute;
  z-index: 99;
  background: #fff;
  box-shadow: 0 10px 10px #38414a26;
}

.slc_address span{
  font-size: 13px;
    font-family: 'Inter';
    font-weight: 500;
    color: #454545;
    margin-bottom: 5.2px;
    display: inline-block;
}

.tag_addr svg {
  width: 0px;
}

.tag_addr{
  border-width: 1px !important;
  margin-right: 5px !important;
}

.address_frm .mb-5 {
  margin-bottom: 35px !important;
}

.address_frm.container p {
  margin: 4px 0px;
}

.farmer_pic {
  text-align: center;
}

.farmer_pic img {
  width: 65%;
}

.head_custom_style {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dlv_srch {
  display: flex;
  align-items: center;
}

.login_action {
  display: flex;
  align-items: center;
}

.login_action svg {
  width: 20px;
  margin-right: 6px;
}

.login_action p {
  margin: 0px !important;
}

.login_action a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.cs_card.card {
  font-family: 'Inter';
  /* box-shadow: rgba(0, 0, 0, 0.1) 0 2px 8px !important; */
  border: 1px solid #f4f4f4;
}

#mmiPickerBot {
  background: #0A0A55 !important;
  color: #fff;
  font-family: 'Inter';
  bottom: 0px !important;
  font-size: 13px !important;
}

#submt_map {
  background: #FFBF00;
  border: none;
  padding: 6px 10px 7px;
}

.product_d_container {
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #ddd;
  border-radius: 12px;
  margin-bottom: 25px;
  background: #fcfcfc;
}

.product_inf_detail {
  display: flex;
  font-family: 'Inter';
  width: 100%;
  font-size: 14px;
  margin: 20px 0px;
}

.product_inf_detail label {
  width: 250px;
  font-weight: 600;
  color: #000;
}

.product_inf_detail p {
  margin: 0;
  width: 100%;
  float: right;
  color: #4e4e4e;
}

.popup_custom {
  position: absolute;
  overflow: auto;
  display: block;
  top: 50%;
  border-radius: 8px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 650px;
  max-height: 85vh;
  outline: none;
}

.address_frm.container {
  padding-top: 25px;
}

.cate_detail {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(308deg, rgba(251, 187, 0, 0.25), rgba(42, 135, 198, 0.21)), url(assets/images/cate-map-bg.png) no-repeat !important;
  margin: 13px 0px;
  font-family: 'Inter';
  padding: 10px;
  background-position: center top !important;
}

.cate_info p {
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 10px;
}

button.ord_btn {
  font-size: 12px;
  background: #0a0a55;
  color: #ffffff;
  padding: 4px 13px;
  border-radius: 25px;
  border: 0;
}

.cate_detail:hover button.ord_btn {
  background: #ffbf00;
}

.cate_img img {
  width: 90px !important;
  height: auto !important;
  border-radius: 50px 50px 0px 50px;
}


.topBrand_img img {
  max-height: 85px;
  width: 90px !important;
  border-radius: 4px;
  height: auto !important;
}

.cate_info {
  padding-left: 15px;
}

.topBrandInfo {
  padding-left: 15px;
}

.topBrandInfo p {
  font-size: 14px;
  margin: 0 0 4px;
  font-weight: 500;
  display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.topBrandInfo h6 {
font-size: 12px;
display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    font-weight: normal;
}

.topBrandInfo h6 svg{
  width: 17px;
  margin-left: -2px;
}

.topBrandInfo h6 svg path {
  stroke: #fdbc01;
}

button.vw_cart {
  width: 100%;
  background: #0a0a55;
  border-color: #0a0a55;
  font-size: 0.875rem;
}

.cs_card.card:hover button.vw_cart {
  background: #ffbf00;
  border-color: #ffbf00;
}

.store_grid {
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin: 13px 0px;
  font-family: 'Inter';
  padding: 10px;
  border: solid 1px #eee;
  box-shadow: rgb(0 0 0 / 5%) 0 2px 8px !important;
  min-height: 110px;
  cursor: pointer;
}

span.tag_lb {
  font-size: 13px;
  color: #454545;
  font-family: inter;
  margin-bottom: 10px;
  font-weight: 500;
}

.table_rs {
  overflow-x: auto;
}

.element {
  background: #f9f9f9;
  padding: 10px 40px;
}

.cart_cn {
  padding: 10px 40px !important;
}

.react-multi-carousel-list {
  padding-bottom: 20px;
}

.provider_search input {
  border: 1px solid #ddd;
  border-radius: 20px;
}

@media screen and (min-width: 992px) {
  .int_reach .col-md-3 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}


@media (max-width: 1199px) {
  .popup_custom {
    width: 70%;
  }

  .bannerDescription h5 {
    font-size: 35px;
    line-height: 46px;
  }

  .bannerDescription p {
    font-size: 16px;
  }

  .y_circle {
    display: none;
  }
}

@media (max-width: 991px) {
  .product_itm {
    width: 1000px !important;
  }

  .popup_custom {
    width: 95%;
  }

  .about_descr h3 {
    font-size: 26px;
  }

  .part_info h3 {
    font-size: 26px;
  }

  .info img {
    width: 65px;
  }

  .info h5 {
    font-size: 14px;
  }

  .bannerDescription h5 {
    font-size: 36px;
    line-height: 48px;
  }

  .bannerDescription p {
    font-size: 15px;
  }

  .agr_banner_btm h3 {
    font-size: 30px;
  }

  .agr_banner_btm p {
    font-size: 16px;
  }
}


@media (max-width: 767px) {
  body {
    font-size: 14px !important;
  }

  .cate_info{
    padding-left: 0px;
  }

  .about_descr h3 {
    font-size: 22px;
  }

  .part_info h3 {
    font-size: 22px;
  }

  .bannerDescription {
    text-align: center;
  }

  .homebanner {
    padding-top: 95px;
    padding-bottom: 40px;
  }

  .bannerDescription h5 span {
    display: inline;
  }

  .bannerDescription h5 {
    font-size: 28px;
    line-height: 38px;
  }

  .bannerDescription p {
    font-size: 15px;
  }

  .farmer_pic img {
    width: 60%;
  }

  .farmer_pic {
    display: none;
  }

  .agr_banner_btm h3 {
    font-size: 22px;
    line-height: 28px;
  }

  .agr_banner_btm p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .dlv_srch {
    display: flex;
    align-items: center;
    position: absolute;
    top: 75px;
    background: linear-gradient(-90deg, #fcbc00 10%, #2486cc);
    width: 96%;
    padding: 5px;
    border-radius: 25px;
  }

  .product_inf_detail {
    flex-wrap: wrap;
  }

  .product_inf_detail p {
    margin-top: 5px;
  }

  .f_title {
    margin-top: 25px;
  }

  .cate_detail {
    display: grid;
    text-align: center;
  }

  .cate_img img {
    width: 70px !important;
    border-radius: 50px;
  }

  .cate_info p {
    font-size: 12px;
    margin: 10px 0px;
  }

  .cart_emt {
    font-size: 20px !important;
    line-height: 30px !important;
    font-weight: 600 !important;
  }
}